@import '../global.scss';


.cart-popup {
    display: grid;
    position: fixed;
    align-items: center;
    height: 100vh;
    align-self: center;
    width: 100%;
    top: 0;
    background: rgba(9, 9, 9, .2);
    z-index: 99999;
    .alert-box {
        display: grid;
        align-items: flex-start;
        padding: 1rem;
        color: #efe;
        background: black;
        height: 30vh;
        width: 100%;
        .message-box {
            display: grid;
            grid-template-columns: auto auto 1fr;
            justify-content: space-between;
            align-items: center;
            text-align: left;
            svg {
                &:nth-child(1) {
                    font-size: 1.75rem;
                    color: rgb(35, 255, 35);
                }
                &:nth-child(2) {
                    justify-self: right;
                }
            }
        }
        button {
            margin: 0px;
            height: 35px;
            border: none;
            border-radius: .25rem;
            &.continue {
                background: white;
            }
            &.checkout {
                background: rgba($color: #0fc7ff, $alpha: 1.0);
            }
        }
    }
}