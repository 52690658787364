@import '../global.scss';

.contact-page {
    h3 {
        margin: .5rem 0rem .25rem 0rem;
    }
   ul {
       list-style-type: none;
       font-size: .9rem;
       color: rgba($color: #4f4f4f, $alpha: 1.0);
       &.second {
           margin-top: .5rem;
       }
       li {
           margin: 0rem;
       }
   } 
}