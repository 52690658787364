@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$mobile-padding: 0% 4%;
$page-padding: 1rem 4% 3rem 4%;
$body-color: rgba(248, 248, 248, 1);
$grey: rgb(146, 146, 146);
$dark-grey: #222;

* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: 'Montserrat', sans-serif;
}

body {
    background: rgba(248, 248, 248, 1);
}
button {
    &:hover {
        cursor: pointer;
    }
}
// PAGE WRAPPERS
.wrapper {
    display: grid;
    min-height: 100vh;
    grid-template-rows: max-content;
    .padded-wrapper {
        display: grid;
        margin-top: 60px;
        padding: $page-padding;
        align-self: flex-start;
        h2 {
            margin-top: .5rem;
        }
    }
}

footer {
    display: grid;
    align-self: flex-end;
    padding: 0rem 4%;
    min-height: 30vh;
    background: rgb(7, 7, 7);
    ul {
        border-bottom: 1px solid rgba($color: #efe, $alpha: .1);
        padding: 1rem 0rem;
        li {
            margin: .5rem 0rem;
            a {
                text-transform: uppercase;
                text-decoration: none;
                color: #efe;
                font-size: .8rem;
                font-weight: bold;
            }
        }
    }
    .social {
        display: flex;
        flex-direction: row;
        padding: 1.5rem 0rem;
        div {
            margin-right: 1rem;
            height: 35px;
            width: 35px;
            border-radius: 100%;
            background: rgba($color: #434343, $alpha: 1.0);
        }
    }
}
// LOGIN FORM

.form-wrapper {
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 99999;
    transition: all ease-in-out 300ms;
    &.show {
        transform-origin: 0px 20px center;
        opacity: 1;
    }
    &.hide {
        opacity: 0;
        transform: translateX(-100%);
    }
    .login-form-wrapper {
        display: grid;
        align-items: center;
        top: 0;
        height: 100vh;
        width: 100%;
        background: rgba($color: #000000, $alpha: .65);
        .login-form {
            display: grid;
            align-items: flex-start;
            align-content: flex-start;
            justify-items: center;
            padding: $mobile-padding;
            background: white;
            box-shadow: 0px 2px 8px rgba($color: #000000, $alpha: .35);
            .form-header {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                width: 100%;
                div:nth-child(1) {
                    display: grid;
                    justify-content: left;
                    width: 100%;
                }
                div:nth-child(2) {
                    display: grid;
                    justify-content: center;
                    justify-items: center;
                    text-align: center;
                    align-content: center;
                    width: 100%;
                    font-weight: bold;
                }
                div:nth-child(3) {
                    display: grid;
                    justify-content: right;
                    padding: 1rem 0rem;
                    width: 100%;
                    font-size: 1.2rem;
                    svg {
                       &:hover {
                           cursor: pointer;
                       }
                    }
                }
            }
            .form-title {
                margin-top: 1rem;
                h3 {
                    text-align: center;
                }
            }
            .form-body {
                display: grid;
                width: 100%;
                margin-top: 1rem;
                input {
                    display: grid;
                    height: 30px;
                    padding: 1.25rem;
                    width: 100%;
                    margin: .5rem 0rem;
                    border: none;
                    border: 1px solid rgba($color: #000000, $alpha: .15);
                    &::placeholder {
                        color: #6d6d6d;
                    }
                }
            }
            .form-options {
                display: flex;
                grid-template-columns: 1fr 1fr;
                width: 100%;
                margin-top: .5rem;
                div:nth-child(1) {
                    display: flex;
                    justify-items: left;
                    justify-content: left;
                    width: 100%;
                    label {
                        margin-left: .75rem;
                        font-size: .8rem;
                        color: #6d6d6d;
                    }
                }
                div:nth-child(2) {
                    display: flex;
                    justify-items: right;
                    justify-content: right;
                    width: 100%;
                    a {
                        text-decoration: none;
                        margin-left: .75rem;
                        font-size: .8rem;
                        color: #6d6d6d;
                    }
                }
            }
            .form-footer {
                display: grid;
                justify-content: center;
                color: #6d6d6d;
                p {
                    text-align: center;
                    font-size: .75rem;
                    margin: 1.5rem 0rem;
                    a {
                        display: inline;
                        color: #3c3c3c;
                    }
                }
                button {
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-content: center;
                    background: black;
                    border: none;
                    color: white;
                    height: 37px;
                    margin: .5rem 0rem; 
                    border-radius: .25rem;
                    svg {
                        margin-left: 1rem;
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

// PARTS PAGE
.individual-parts-page {
    display: grid;
    h1 {
        font-weight: normal;
    }
    div {
        display: grid;
        width: 100%;
        img {
            margin-top: 1rem;
            width: 100%;
            background-color: #969696;
        }
    }

    .image-thumbnails {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr) ) ;
        row-gap: 1rem;
        column-gap: 1rem;
        .thumbs {
            margin-top: 1rem;
            width: 100%;
            height: 100px;
            background:rgb(146, 146, 146);
        }
    }
    button {
        margin-top: 1rem;
        height: 35px;
        background: black;
        border: none;
        color: #fff;
        border-radius: .25rem;
        box-shadow: 0px 1px 4px rgba($color: #000000, $alpha: .5);
        &.view-details {
            justify-self: left;
            background: transparent;
            box-shadow: none;
            color: rgb(7, 7, 7);
            text-decoration: underline;
        }
    }
    .part-description {
        margin-top: 2rem;
        color:#434343;
    }
    .part-details {
        display: grid;
    }
    .quantity {
        display: flex;
        width: 100%;
        margin-top: 1rem;
        box-shadow: 0px 1px 4px rgba($color: #000000, $alpha: .25);
        padding: .25rem;
        border-radius: .25rem;
        width: max-content;
        div {
            display: grid;
            align-items: flex-start;
            width: max-content;
            svg {
                align-self: center;
                align-content: center;
                background: rgba($color: #000000, $alpha: .9);
                border-radius: .25rem;
                padding: .25rem 0rem;
                font-size: 1.25rem;
                color: white;
            }
            &.quanitityDisplay {
                display: grid;
                justify-content: center;
                align-content: center;
                align-items: center;
                padding: .25rem .5rem;
                font-size: .8rem;
            }
        }
    }
}

// HOME PAGE
.header {
    display: grid;
    margin-top: 50px;
    padding: 0px;
    width: 100%;
    object-fit: scale-down;
    object-position: top right;
    background-image: url('./Images/parts_header.png');
    height: 50vh;
    padding: 1rem 4%;
    svg {
        display: grid;
        position:absolute;
        right: 4%;
        margin-top: 2rem;
        height: 55px;
        opacity: 60%;
    }
}

// UPDATES PAGE
.updates-page {
    h1 {
        margin-bottom: 1rem;
    }
    h2 {
        margin-bottom: .5rem;
    }
    ul {
        list-style-type: none;
        margin: .25rem 0rem .5rem 0rem;
        li {
            color: rgba($color: #000000, $alpha: .65);
            font-size: .9rem;
        }
    }
}

// CONTACT PAGE


$backgound-color: #000;
$breakpoints: ('small': 438px, 'medium': 768px, 'large': 1200px);
@mixin responsive($breakpoint) {
    $value: map-get($breakpoints, $breakpoint);
    @if $value !=null {
        // Prints a media query based on the value
        @media (min-width: $value) {
            @content;
        }
    }
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Please make sure it is defined in `$breakpoints` map.";
    }
}

@include responsive(medium) {
    .form-wrapper {
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 99999;
        transition: all ease-in-out 300ms;
        &.show {
            transform-origin: 0px 20px center;
            opacity: 1;
        }
        &.hide {
            opacity: 0;
            transform: translateX(-100%);
        }
        .login-form-wrapper {
            .login-form {
                justify-self: center;
                max-width: 500px;
                padding: 2rem 1.5rem;
            }
        }
    }
}
@include responsive(large) {

}