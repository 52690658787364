@import '../global.scss';

nav {
    height: 50px;
    background: $body-color;
    box-shadow: 0px 0px 6px rgba(0,0,0, .2);
    z-index: 999999;
    width: 100%;
    position: fixed;
    padding: $mobile-padding;
    &.mobile-nav {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        span {
            &.cart-counter {
                display: flex;
                position: absolute;
                font-size: .6rem;
                top: .35rem;
                text-align: right;
                padding: .10rem;
                border-radius: 1rem;
            }
        }
        div {
            display: grid;
            svg {
                font-size: 1.5rem;
                margin-left: .5rem;
                &:hover {
                    cursor: pointer;
                }
            }
            &:nth-child(2) {
                display: flex;
                justify-content: right;
                justify-items: right;
            }
        }
    }

    &.desktop-nav {
        display: none;
        justify-content: space-between;
        grid-template-columns: auto 1fr;
        align-content: center;
        align-items: center;
        div {
            svg {
                height: 50px;
                .cls-1 {
                    fill: $body-color;
                }
            }
            ul {
                display: flex;
                padding: .15rem 0rem;
                li {
                    display: flex;
                    a {
                        text-decoration: none;
                        color: $grey;
                        font-size: 1rem;
                        margin-left: 2rem;
                        &:hover {
                            color: black;
                        }
                    }
                }
            }
        }
    }
}
.desktop-nav-two {
    display: none;
    width: 100%;
    justify-content: right;
    position: fixed;
    margin-top: 50px;
    height: 30px;
    padding: $mobile-padding;
    ul {
        display: flex;
        padding: .10rem 0rem;
        li {
            display: flex;
            align-items: center;
            a, button {
                display: flex;
                align-items: center;
                align-content: center;
                text-decoration: none;
                background: none;
                border: none;
                color: rgba($color: #1c1c1c, $alpha: 1.0);
                font-size: .8rem;
                margin-left: 1rem;
                &:hover {
                    color: $grey;
                    cursor: pointer;
                }
                svg {
                    font-size: 1rem;
                    margin-right: .25rem;
                }
            }
        }
    }
}
.drop-down-menu {
    display: grid;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    padding: 50px 0%;
    background: white;
    transition: ease-in-out 400ms;
    align-items: flex-start;
    align-content: flex-start;
    z-index: 9999;
    overflow-y: scroll;
    div {
        width: 100%;
        padding: 10px 4%;
            &.items {
            display: grid;
            padding: .5rem 0rem;
            grid-template-columns: 50px 2fr auto auto;
            // Item image to be 75px in height
            align-content: flex-start;
            border-bottom: none;
            column-gap: 1rem;
            border-bottom: 1px solid rgba($color: #000000, $alpha: .1);
            div {
                display: grid;
                justify-content: left;
                border-bottom: none;
                padding: 0px;
                margin: 0px;
                img {
                    width: 100%;
                }
                ul {
                    margin: 0px;
                    padding: 0px;
                }
                li {
                    padding: 0px;
                    margin: 0px;
                    &.title {
                        font-weight: bold;
                    }
                    &.cart-quantity {
                        font-size: .8rem;
                    }
                    &.cart-category {
                        color: rgb(136, 136, 136);
                        font-size: .8rem;
                    }
                }
                svg {
                    font-size: .9rem;
                }
            }
            span {
                justify-self: center;
            }
        }
        &.cart {
            display: grid;
            padding: 20px 4%; 
            justify-content: center;
            justify-items: center;
        h3 {
            display: grid;
            margin-bottom: 10px;
            display: grid;
            }
        }
        svg {
            display: flex;
            flex-direction: column;
            margin-right: 5px;
            color: $dark-grey;
            font-size: 1.2rem
        }
        a, button {
            display: flex;
            background: transparent;
            border: none;
            align-self: center;
            align-content: center;
            align-items: center;
            vertical-align: middle;
            color: $grey;
            text-transform: uppercase;
            font-size: .8rem;
            font-weight: 600;
            letter-spacing: 1.1px;
            &:hover {
                cursor: pointer;
            }
        }
        &.summary {
            padding: 20px 4%;
            width: 100%;
            justify-content: space-between;
            .checkout-btn {
                display: flex;
                margin-top: 2rem;
                width: 100%;
                height: 35px;
                justify-content: center;
                border-radius: .25rem;
                background: black;
                color: white;
                border: none;
                align-self: center;
                align-content: center;
                align-items: center;
                vertical-align: middle;
                text-transform: uppercase;
                font-size: .8rem;
                font-weight: 600;
                letter-spacing: 1.1px;
                box-shadow: 0px 2px 6px rgba($color: #000000, $alpha: .5);
                &:hover {
                    cursor: pointer;
                }
            }
            h3 {
                margin-bottom: .75rem;
            }
            .totals {
                display: flex;
                padding: 0px;
                border-bottom: none;
                width: 100%;
                margin: .25rem 0rem;
                div:nth-child(1) {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    padding: 0px;
                }
                div:nth-child(2) {
                    display: flex;
                    flex-direction: row-reverse;
                    padding: 0px;
                }
            }
        }
    }
    ul {
        list-style-type: none;
        padding: 0px 4%;
        margin: 0px;
        li {
            font-size: .9rem;
            margin: 15px 5px;
            &.heading {
                color: $dark-grey;
                margin: 5px 0px;
                font-size: 1rem;
            }
            &.last {
                padding-bottom: 15px;
                border-bottom: 2px solid rgba($color: $grey, $alpha: .2);
            }
            a {
                text-decoration: none;
                color: $grey;
                &:hover {
                    color: black;
                }
            }
        }
    }
    &.show {
        transform: scaleY(1);
        transform-origin: top;
    }
    &.hide {
        transition: ease-in-out 100ms;
        transform: scaleY(0);
        transform-origin: top;
    }
}

.search-menu {
    display: grid;
    position: fixed;
    top: 0;
    z-index: 9999999;
    align-content: flex-start;
    align-items: flex-start;
    width: 100%;
    &.hide {
        transition: all ease-in-out 200ms;  
        transform: translateY(-100%);
    }
    &.show {
        transition: all ease-in-out 500ms;  
        transform: translateX(0%);
        background-color: white;
        min-height: 100vh;
        width: 100%;
    }
    .search-bar {
        display: flex;
        height: 50px;
        width: 100%;
        justify-content: space-between;
        margin: 0px;
        padding: 5px 4%;
        align-items: center;
        align-content: center;
        box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: .25);
        input {
            display: grid;
            margin: 0;
            padding: 0;
            width: 95%;
            height: 100%;
            border: none;
            border-radius: 55px;
            box-shadow: inset 0px 1px 6px rgba($color: #000000, $alpha: .25);
            padding: 1rem 2.5rem;
            &:active, &:focus {
                border: none;
                outline: none;
            }
        }
        svg {
            color:rgba(72, 72, 72, .5);
            &.close {
                display: grid;
                font-size: 2rem;
                justify-items: right;
            }
            &.open {
                font-size: 1.5rem;
                position: absolute;
                margin-left: .5rem;
            }
        }
    }
    .search-results {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        justify-content: space-between;
        padding: 1rem 4%;
        align-self: flex-start;
        ul {
            list-style-type: none;
        }
        .searched {
            display: grid;
             grid-column: 1 / span 2;
        }
        .result-items {
            display: flex;
            flex-direction: column;
            grid-template-columns: 1fr 1fr;
            .item-image {
                display: grid;
                margin-top: 2rem;
                height: 150px;
                width: 100%;
                background: rgb(197, 197, 197);
            }
            .item-name {
                font-size: .9rem;
                padding: 0rem .5rem;
            }
            .item-type {
                font-size: .9rem;
                color: rgba($color: #919191, $alpha: 1.0);
                padding: 0rem .5rem;
            }
            .item-price {
                font-size: .9rem;
                margin-top: .5rem;
                padding: 0rem .5rem;
            }
        }
    }
}

@include responsive(medium) {
    nav {
        &.desktop-nav {
            display: flex;
        }
        &.mobile-nav {
            display: none;
        }
    }
    .desktop-nav-two {
        display: flex;
    }
}
@include responsive(large) {

}
